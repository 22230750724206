import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ModeService {
  mode$ = new BehaviorSubject<'login' | 'register' | 'reset' | 'verification'>('login');
  loginMethod$ = new BehaviorSubject<'password' | 'otp' | 'webid'>('password');
  password: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  setMode(mode: 'login' | 'register' | 'reset' | 'verification', queryParams?: Params) {
    this.mode$.next(mode);
    this._router.navigate([location.pathname], {
      queryParams: {mode: this.getMode(), ...queryParams},
      queryParamsHandling: 'merge',
    });
  }

  setLoginMethod(loginMethod: 'password' | 'otp' | 'webid') {
    this.loginMethod$.next(loginMethod);
  }

  getMode() {
    return this.mode$.value;
  }
}
