import {AsyncPipe, NgIf} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AsyncValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CustomValidators, PropertyFormControl} from '@aztrix/helpers';
import {ErrorType, ProfileContext, PropertyType} from '@aztrix/models';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';

import {LoginFormComponent} from './components/login-form/login-form.component';
import {OtpLoginFormComponent} from './components/otp-login-form/otp-login-form.component';
import {RegisterFormComponent} from './components/register-form/register-form.component';
import {ResetPasswordFormComponent} from './components/reset-password-form/reset-password-form.component';
import {VerificationFormComponent} from './components/verification-form/verification-form.component';
import {WebidLoginFormComponent} from './components/webid-login-form/webid-login-form.component';
import {ModeService} from './services/mode.service';

@UntilDestroy()
@Component({
  selector: 'ax-auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss', './components/form.scss'],
  standalone: true,
  imports: [
    NgIf,
    LoginFormComponent,
    OtpLoginFormComponent,
    WebidLoginFormComponent,
    RegisterFormComponent,
    ResetPasswordFormComponent,
    VerificationFormComponent,
    AsyncPipe,
  ],
})
export class AuthComponent implements OnInit, OnChanges, OnDestroy {
  @Input() error: {type: ErrorType; text: string};
  @Input() mode: 'login' | 'register' | 'reset' | 'verification' = 'login';
  @Input() loginMethod: 'password' | 'otp' | 'webid' = 'password';
  @Input() allowMobile = false;
  @Input() alistClaimCode?: string;
  @Input() logo = 'custom:aztrixlogo';
  @Input() individualRegisterAllowed = true;

  @Input() claim: 'username' | 'alistname' = 'username';
  @Input() claimAsyncValidator: AsyncValidatorFn;
  @Output() registerComplete = new EventEmitter();
  @Output() loginComplete = new EventEmitter<{authToken: string | undefined; password?: string}>();

  mode$ = this._modeService.mode$;
  loginMethod$ = this._modeService.loginMethod$;

  resetPassword = new FormGroup({
    username: new FormControl<string>(''),
    password: new FormControl<string>('', [Validators.required, Validators.minLength(8)]),
  });

  loginForm = new FormGroup({
    login: new FormControl<string>(''),
    password: new FormControl<string>(''),
  });

  otpLoginForm = new FormControl('', [CustomValidators.validateLoginValue()]);

  registerForm: FormGroup<ControlsOf<any>> = new FormGroup({
    alistName: new FormControl<string | undefined>(undefined, [
      Validators.required,
      CustomValidators.nameValidator(),
    ]),
    properties: new FormGroup({
      [PropertyType.USERNAME]: PropertyFormControl.createByType(PropertyType.USERNAME, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
      [PropertyType.EMAIL]: PropertyFormControl.createByType(PropertyType.EMAIL, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
      [PropertyType.BIRTHDAY]: PropertyFormControl.createByType(PropertyType.BIRTHDAY, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
      [PropertyType.FIRST_NAME]: PropertyFormControl.createByType(PropertyType.FIRST_NAME, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
      [PropertyType.LAST_NAME]: PropertyFormControl.createByType(PropertyType.LAST_NAME, [
        CustomValidators.propertyRequired,
        CustomValidators.propertyValid,
      ]),
      [PropertyType.ORGANISATION_NAME]: PropertyFormControl.createByType(
        PropertyType.ORGANISATION_NAME,
        [CustomValidators.propertyRequired, CustomValidators.propertyValid]
      ),
      [PropertyType.ORGANISATION_TYPE]: PropertyFormControl.createByType(
        PropertyType.ORGANISATION_TYPE,
        [CustomValidators.propertyRequired, CustomValidators.propertyValid]
      ),
      [PropertyType.LEGAL_ID]: PropertyFormControl.createByType(PropertyType.LEGAL_ID, [
        CustomValidators.propertyValid,
      ]),
    }),
    legal: new FormGroup({
      terms: new FormControl(false, Validators.requiredTrue),
      privacy: new FormControl(false, Validators.requiredTrue),
      newsletter: new FormControl(false),
    }),
    type: new FormControl<string>(ProfileContext.INDIVIDUAL),
    password: new FormControl<string>('', [Validators.required, Validators.minLength(8)]),
    legalRepresentative: new FormGroup({
      role: new FormControl<string>('', Validators.required),
      properties: new FormGroup({
        [PropertyType.FIRST_NAME]: PropertyFormControl.createByType(PropertyType.FIRST_NAME, [
          CustomValidators.propertyRequired,
          CustomValidators.propertyValid,
        ]),
        [PropertyType.LAST_NAME]: PropertyFormControl.createByType(PropertyType.LAST_NAME, [
          CustomValidators.propertyRequired,
          CustomValidators.propertyValid,
        ]),
        [PropertyType.MOBILE_PHONE]: PropertyFormControl.createByType(PropertyType.MOBILE_PHONE, [
          CustomValidators.propertyRequired,
          CustomValidators.propertyValid,
        ]),
      }),
    }),
  });

  private _subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _modeService: ModeService
  ) {}

  ngOnInit(): void {
    if (!this.individualRegisterAllowed) {
      this.registerForm.get('type')?.setValue(ProfileContext.ORGANISATION);
    }
    this._route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
      if (params['name']) {
        this._modeService.mode$.next('register');
      } else if (params['mode']) {
        this._modeService.mode$.next(params['mode']);
      } else if (this.mode) {
        this._modeService.setMode(params['mode'] ?? this.mode);
      }
    });

    this._subscription = this.mode$.subscribe((mode) => {
      this.mode = mode;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode && this.mode) {
      this._modeService.setMode(this.mode);
    }

    if (changes.loginMethod && this.loginMethod) {
      this._modeService.setLoginMethod(this.loginMethod);
    }

    if (changes.claimAsyncValidator && this.claimAsyncValidator) {
      this.registerForm.get('alistName')?.setAsyncValidators(this.claimAsyncValidator);
      this.registerForm
        .get('properties')
        ?.get(PropertyType.USERNAME)
        ?.get('value')
        ?.setAsyncValidators(this.claimAsyncValidator);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  get resetPasswordEmail(): string | undefined {
    const loginFormEmail = this.loginForm?.get('login')?.value?.includes('@')
      ? this.loginForm?.get('login')?.value
      : undefined;
    return loginFormEmail || undefined;
  }

  registerCompleted() {
    this.registerComplete.emit();
  }
}
